export const streamerSignInRoute = () => '/streamer/sign-in' as const;

export const advertiserSignInRoute = () => '/advertiser/sign-in' as const;

export const adminSignInRoute = () => `/admin/sign-in` as const;

export const signOutRoute = () => '/sign-out' as const;

export const adminSignOutRoute = () => `/admin/sign-out` as const;

export const authMicrosoftCallbackRoute = () =>
  '/auth/microsoft/callback' as const;

export const authGoogleCallbackRoute = () => '/auth/google/callback' as const;

export const authTwitchCallbackRoute = () => '/auth/twitch/callback' as const;

export const authAdminCallbackRoute = () => '/admin/auth/callback' as const;

export const authRoutes = {
  [streamerSignInRoute()]: 'pages/auth/streamerSignIn/route.tsx',

  [advertiserSignInRoute()]: 'pages/auth/advertiserSignIn/route.tsx',

  [adminSignInRoute()]: 'pages/auth/adminSignIn/route.tsx',

  [signOutRoute()]: 'pages/auth/signOut.tsx',

  [adminSignOutRoute()]: 'pages/auth/adminSignOut.tsx',

  [authMicrosoftCallbackRoute()]: 'pages/auth/microsoftCallback.tsx',

  [authGoogleCallbackRoute()]: 'pages/auth/googleCallback.tsx',

  [authTwitchCallbackRoute()]: 'pages/auth/twitchCallback.tsx',

  [authAdminCallbackRoute()]: 'pages/auth/adminCallback.tsx'
};
